import { SiteTicker } from '@/components/cms/SiteTicker';

import DynamicComponent from './DynamicComponent';

const SbSiteTicker = ({ blok: { children, backgroundColor, foregroundColor } }) => {
  return (
    <SiteTicker backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
      {children.map((blok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
    </SiteTicker>
  );
};

export default SbSiteTicker;
